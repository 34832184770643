<template>
  <div class="flex justify-between">
    <div class="flex flex-one flex-wrap">
      <div
        class="m-r-sm text-lightblue flex items-center search-mx-wd-2 m-b-sm"
      >
        <span class="flex-s m-r-xs">事件分类:</span>
        <a-select
          v-model="eventTypeId"
          placeholder="请选择事件分类"
          class="flex-one"
          @change="selectEventType"
        >
          <a-select-option
            v-for="item in eventTypeList"
            :key="item.parentEventId"
            :value="item.parentEventId"
          >
            {{ item.parentEventName }}
          </a-select-option>
        </a-select>
      </div>
      <div
        class="m-r-sm text-lightblue flex items-center search-mx-wd-2 m-b-sm"
      >
        <span class="flex-s m-r-xs">事件详情:</span>
        <a-select
          v-model="eventContentId"
          placeholder="请选择事件内容"
          class="flex-one"
          @change="selectEventContent"
        >
          <a-select-option
            v-for="item in eventContentList"
            :key="item.eventKey"
            :value="item.eventKey"
          >
            {{ item.eventName }}
          </a-select-option>
        </a-select>
      </div>
      <div
        class="m-r-sm text-lightblue flex items-center search-mx-wd-2 m-b-sm"
      >
        <span class="flex-s m-r-xs">归属路线:</span>
        <!-- <a-select
          v-model="routeId"
          placeholder="请选择路线"
          class="flex-one"
          @change="selectRoute"
        >
          <a-select-option
            v-for="(item, index) in routeList"
            :key="index"
            :value="item.roadId"
          >
            {{ item.roadName }}
          </a-select-option>
        </a-select> -->
        <a-select
          placeholder="请选择所属路线"
          v-model="routeId"
          class="flex-one"
          @change="selectRoute"
        >
          <a-select-option
            v-for="item in roadList"
            :key="item.roadCode"
            :value="item.roadCode"
          >
            {{ item.roadCode + ' ' + item.roadName }}
          </a-select-option>
        </a-select>
      </div>

      <div
        class="m-r-sm text-lightblue flex items-center search-mx-wd-2 m-b-sm"
        v-if="false"
      >
        <span class="flex-s m-r-xs">涉桥涉隧:</span>
        <a-select
          v-model="bridgeId"
          placeholder="请选择涉桥涉隧属性"
          class="flex-one"
          @change="(e) => selectBridge(e)"
        >
          <a-select-option
            v-for="item in bridgeTunnelList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div
        class="m-r-sm text-lightblue flex items-center  m-b-sm"
      >
        <span class="flex-s m-r-xs">时间:</span>
        <!-- <a-range-picker
          v-model="dataRange"
          :placeholder="['开始时间', '结束时间']"
          class="flex-one"
          @change="changeDataRange"
          format="YYYY-MM-DD HH:mm:ss"
          :showTime="{
            defaultValue: [defaultStart, defaultEnd],
          }"
          :disabledDate="disabledDate"
        ></a-range-picker> -->

        <a-time-picker
          :defaultValue="moment(startTime, 'HH:mm:ss')"
          :allowClear="false"
          @change="
            (val, dateStrings) => changeTime(val, dateStrings, 'startTime')
          "
          format="HH:mm:ss"
        />
        <span class="m-l-xs m-r-xs">~</span>
        <a-time-picker
          :disabledHours="getDisabledHours"
          :disabledMinutes="getDisabledMinutes"
          :disabledSeconds="getDisabledSeconds"
          :defaultValue="moment(endTime, 'HH:mm:ss')"
          :allowClear="false"
          @change="
            (val, dateStrings) => changeTime(val, dateStrings, 'endTime')
          "
          format="HH:mm:ss"
        />
      </div>
      <a-button type="primary" class="m-r-sm btn-search" @click="searchHandle">
        搜索
      </a-button>
      <a-button class="btn-reset" @click="resetHandle">
        重置
      </a-button>
    </div>
    <!-- <div class="flex">
      <span class="span-line m-l-md m-r-md"></span>
     
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
const DEFAULT_MOMENT = [
  moment(`${moment().format('YYYY-MM-DD')} 00:00:00`),
  moment(`${moment().format('YYYY-MM-DD')} 23:59:59`),
];
export default {
  name: 'SearchBar',
  data() {
    return {
      dataRange: DEFAULT_MOMENT,
      startTime: '00:00:00',
      endTime: '23:59:59',
      routeList: [],
      routeId: undefined,
      eventTypeId: undefined,
      eventTypeList: [],
      eventContentList: [],
      eventContentId: undefined,
      disabledDate: (currentDate) => {
        return (
          (currentDate && currentDate < moment().startOf('day')) ||
          (currentDate && currentDate > moment().endOf('day'))
        );
      },
      /*  bridgeTunnelList: [
        { id: 0, value: 'all', name: '全部', type: 'all' },
        { id: 1, value: 'bridge', name: '涉桥', type: 'bridge' },
        { id: 2, value: 'tunnel', name: '涉隧', type: 'tunnel' },
        { id: 3, value: 'nobt', name: '无', type: 'nobt' },
      ], */
      /*    bridgeId: undefined, */
      parentEventId: '',
    };
  },
  computed: {
    ...mapState({
      roadList: (state) => state.collect.roadList,
    }),
    defaultStart() {
      return moment('00:00:00', 'HH:mm:ss');
    },
    defaultEnd() {
      return moment('23:59:59', 'HH:mm:ss');
    },
  },
  created() {
    this.getRoadList();
    //获取事件字典
    this._getAllEvents();
    //获取字典
    this.parentEventId = this.$route.query.parentEventId;
    // this.eventTypeId = this.parentEventId;
  },
  methods: {
    moment,
    changeTime(val, dateStrings, type) {
      if (type === 'startTime') {
        this.startTime = dateStrings;
      } else {
        this.endTime = dateStrings;
      }
    },
    getDisabledHours() {
      let hours = [];
      let time = this.startTime;
      let timeArr = time.split(':');
      for (var i = 0; i < parseInt(timeArr[0]); i++) {
        hours.push(i);
      }
      return hours;
    },
    getDisabledMinutes(selectedHour) {
      let time = this.startTime;
      let timeArr = time.split(':');
      let minutes = [];
      if (selectedHour == parseInt(timeArr[0])) {
        for (var i = 0; i < parseInt(timeArr[1]); i++) {
          minutes.push(i);
        }
      }
      return minutes;
    },
    getDisabledSeconds(selectedHour, selectedMinute) {
      let time = this.startTime;
      let timeArr = time.split(':');
      let second = [];
      if (
        selectedHour == parseInt(timeArr[0]) &&
        selectedMinute == parseInt(timeArr[1])
      ) {
        for (var i = 0; i <= parseInt(timeArr[2]); i++) {
          second.push(i);
        }
      }
      return second;
    },
    _getAllEvents() {
      this.$api.aiCenterY.getAllEvents().then((res) => {
        if (res.code === CODE_OK) {
          this.eventTypeList = res.data;
          this.selectEventType(this.parentEventId);
        }
      });
    },
    changeDataRange(date) {
      this.dataRange = date;
    },
    selectRoute(e) {
      this.routeId = e;
    },
    selectEventType(e) {
      this.eventTypeId = e;
      // this._getEventTypeNameAll(this.eventTypeId, false);
      this.eventContentId = undefined;
      this.eventContentList = this.eventTypeList.find(
        (item) => item.parentEventId === e
      ).children;
    },
    selectEventContent(e) {
      this.eventContentId = e;
    },
    searchHandle() {
      let searchParams = {
        currPage: 1,
        /*   endTime: this.dataRange
          ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '', */
        endTime: this.endTime
          ? `${moment().format('YYYY-MM-DD')} ${this.endTime}`
          : '',
        eventKey: this.eventContentId,
        pageSize: 10,
        parentEventKey: this.eventTypeId,
        roadCode: this.routeId,
        startTime: this.startTime
          ? `${moment().format('YYYY-MM-DD')} ${this.startTime}`
          : '',
        /*  startTime: this.dataRange
          ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '', */
      };
      this.$emit('search', searchParams);
    },

    resetHandle() {
      this.dataRange = DEFAULT_MOMENT;
      this.eventContentId = undefined;
      this.routeId = undefined;
      this.eventTypeId = undefined;
      this.eventContentList = [];
      this.searchHandle();
    },
    /* _getEventRoadInfo() {
      this.$api.aiCenterY.getEventRoadInfo().then((res) => {
        if (res.code === 200) {
          this.routeList = res.data;
        }
      });
    },
    _getEventTypeNameAll(parentId, flag) {
      this.$api.aiCenterY.getTypeNameAll(parentId).then((res) => {
        if (res.code === 200) {
          if (flag) {
            this.eventTypeList = res.data;
          } else {
            this.eventContentList = res.data;
          }
        }
      });
    },
    searchHandle() {
      let obj = {
        roadId: this.routeId ? String(this.routeId) : '',
        eventType: this.eventTypeId ? this.eventTypeId : '',
        eventContent: this.eventContentId ? this.eventContentId : '',
        sfsq:
          this.bridgeId === 'bridge'
            ? 1
            : this.bridgeId === 'all' || !this.bridgeId
            ? ''
            : 0,
        sfss:
          this.bridgeId === 'tunnel'
            ? 1
            : this.bridgeId === 'all' || !this.bridgeId
            ? ''
            : 0,
        fxsj: this.dataRange
          ? moment(this.dataRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endsj: this.dataRange
          ? moment(this.dataRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      this.setSearchParams(obj);
      this.getTotayList({ ...this.searchParams, ...obj });
    },
    resetHandle() {
      this.dataRange = null;
      this.routeId = undefined;
      this.eventTypeId = undefined;
      this.eventContentId = undefined;
      this.bridgeId = undefined;
      this.searchHandle();
    },




    selectBridge(e) {
      this.bridgeId = e;

    },
    exportHandle() {
      let data = this.searchParams;
      this.$api.aiCenterY.exportData(data).then((res) => {
        handleExportFile(res, '全部事件列表.xls');
      });
    },

    ...mapMutations(['setSearchParams']), */
    ...mapActions(['getRoadList']),
  },
};
</script>

<style scoped></style>
