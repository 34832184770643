var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"treeDom"}},[_c('a-tree',{ref:"treeDom",attrs:{"id":"treeDomWrap","load-data":_vm.onLoadCameraSelectData,"tree-data":_vm.cameralList,"on":{ click: function () {} },"expandedKeys":_vm.expandedTreeKeys},on:{"update:expandedKeys":function($event){_vm.expandedTreeKeys=$event},"update:expanded-keys":function($event){_vm.expandedTreeKeys=$event},"expand":_vm.onExpand,"select":_vm.treeNodeSelect},scopedSlots:_vm._u([{key:"customtitle",fn:function(tags){return [[(tags.type === "camera" && !_vm.$g.treeDefinate)?_c('div',{staticClass:"flex items-center",style:(_vm.getTreeListWd),attrs:{"title":_vm.getTitle(tags.khPile, tags.poiName, tags.derection)}},[_c('span',{class:{
              'm-r-xs icon-style': true,
              online: String(tags.onlineStatus) === '1',
              offline: String(tags.onlineStatus) === '0',
              error: String(tags.onlineStatus) === '2',
            }}),_c('span',{staticClass:"ellipsis full-width"},[_vm._v(" "+_vm._s(tags.title)+" "+_vm._s(tags.poiName)+" "+_vm._s(_vm._f("getDirectionArrow")(tags.derection)))])]):(tags.type === "camera" && _vm.$g.treeDefinate)?_c('div',{staticClass:"flex"},[_c('span',{class:{
              'm-r-xs icon-style m-t-sm relative': true,
              online: String(tags.onlineStatus) === '1',
              yunOffline: String(tags.onlineStatus) === '0',
              error: String(tags.onlineStatus) === '2',
            },staticStyle:{"left":"-2px"}}),_c('div',{style:(_vm.getTreeListWd)},[_c('span',{staticClass:"ellipsis full-width block"},[_vm._v(" "+_vm._s(tags.khPile)),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRoadtype),expression:"isRoadtype"}],staticClass:"m-l-xs"},[_vm._v(_vm._s(_vm.getDerection(tags)))])]),_c('span',{staticClass:"ellipsis full-width f12 block ellipsis text-lightwhite",attrs:{"title":tags.cameraName}},[_vm._v(_vm._s(tags.cameraName))])])]):_c('div',{staticClass:"flex fl-bg",class:{ current: tags.check }},[[_c('span',{staticClass:"m-r-xs"},[_vm._v(_vm._s(tags.title))])],_c('span',{staticClass:"f12"},[_vm._v(" ("),_c('span',{staticClass:"text-lightblue"},[_vm._v(_vm._s(tags.online))]),_c('span',{staticClass:"m-l-xs m-r-xs"},[_vm._v("/")]),_vm._v(_vm._s(tags.total)+") ")])],2)]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }