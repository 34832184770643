<template>
  <div class="full-width full-height">
    <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
      <home-left-menu :list="subList" @showCom="showComHandle" class="m-b-sm" />
      <template>
        <template v-if="currentType === 'time'">
          <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
            <div
              class="m-b-sm text-lightblue pointer p-w-sm"
              v-for="(item, index) in dataList"
              :key="index"
              @click="searchDataList(item)"
            >
              {{ item.sj }}
            </div>
          </vue-scroll>
        </template>
        <template v-else>
          <tree-list
            ref="treeListDom"
            v-if="cameralList.length > 0"
            :cameralList="cameralList"
            :type="currentType"
          />
        </template>
        <div v-if="loading" class="full-width flex items-center justify-center">
          <a-spin />
        </div>
      </template>
    </vue-scroll>
  </div>
</template>

<script>
import HomeLeftMenu from './LeftMenu';
import TreeList from './TreeList';
import { queryStaticDataList } from '@/tools';
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'CameralRouteEvent',
  data() {
    return {
      subList: [
        /* {
          id: 0,
          name: '路线',
          type: 'road',
        },
        {
          id: 1,
          name: '区域',
          type: 'province',
        },
        {
          id: 2,
          name: '单位',
          type: 'organization',
        }, */
        {
          id: 3,
          name: '时间',
          type: 'time',
        },
      ],
      activeCom: 'TreeList',
      curIndex: 0,
      currentType: 'time',
      loading: false,
      dataList: [],
    };
  },
  components: {
    HomeLeftMenu,
    TreeList,
  },
  computed: {
    ...mapState({
      cameralList: (state) => state.home.cameralList,
      searchParams: (state) => state.aiCenter.searchParams,
    }),
  },
  watch: {},
  mounted() {
    // this.getRoadCameral('road');
    this._getDateList();
  },
  methods: {
    ...mapMutations(['setCameralList', 'setBarData', 'setSearchParams']),
    ...mapActions(['getTotayList']),
    showComHandle(item) {
      /* this.curIndex = item.id;
      this.setCurrentComIndex(this.curIndex);
      //传递给父组件
      this.$emit('routeHandle', this.curIndex); */
      let type = item.type;
      this.currentType = type;
      this.curIndex = item.id;
      this.selectData(type);
    },

    searchTodayList(item) {
      let data = {
        endTime: `${item.sj} 23:59:59`,
        eventAlarmDays: null,
        eventAlarmType: '',
        startTime: `${item.sj} 00:00:00`,
      };
      /*  let newData = { ...this.searchParams, ...data }; */
      this.$api.aiCenterY.getEventHistogramStatistics(data).then((res) => {
        if (res.code === 200) {
          this.setBarData(res.data);
        }
      });
    },
    _getDateList() {
      this.$api.aiCenterY.getDateList().then((res) => {
        if (res.code === 200) {
          this.dataList = res.data;
          let searchParams = {
            ...this.searchParams,
            ...{ sj: `${this.dataList[0].sj} 00:00:00` },
          };
          this.searchTodayList(this.dataList[0]);
          this.setSearchParams(searchParams);
          this.getTotayList(searchParams);
        }
      });
    },
    searchDataList(item) {
      let searchParams = {
        ...this.searchParams,
        ...{ sj: `${item.sj} 00:00:00`, currPage: 1, pageSize: 10 },
      };
      this.setSearchParams(searchParams);
      this.searchTodayList(item);
      this.getTotayList(searchParams);
    },
    selectData(type) {
      switch (type) {
        case 'road':
          this.getRoadCameral(type);
          break;
        case 'province':
          this.getProvinceCameral(type);
          break;
        case 'organization':
          this.getOrganizationCameral(type);
          break;
      }
    },
    getRoadCameral(type) {
      let data = {
        provinceCode: '',
        regionCode: '',
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;

      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
      });
    },
    getProvinceCameral(type) {
      let data = {
        cityCode: '',
        provinceCode: '',
        regionCode: '',
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      // this.cameralList = [];
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
      });
    },
    // eslint-disable-next-line no-unused-vars
    getOrganizationCameral(type) {
      let data = {
        organizationId: '',
        provinceCode: '',
        regionCode: '',
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: 'province',
        data: data,
      };
      this.loading = true;

      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;

        this.setCameralList(res.data);
      });
    },
  },
};
</script>

<style scoped></style>
