<template>
  <div class="full-height">
    <div class="f-l full-height m-r-md" v-if="showTime">
      <div class="on-con relative p-md full-width full-height">
        <span class="cor absolute t-lf"></span>
        <span class="cor absolute t-rf"></span>
        <span class="cor absolute b-lf"></span>
        <span class="cor absolute b-rf"></span>
        <div class="con-main flex items-center full-height">
          <cameral-route-event />
        </div>
      </div>
    </div>
    <div class="f-r full-height">
      <event-content-info />
    </div>
  </div>
</template>

<script>
import CameralRouteEvent from '@/components/CameralRouteEvent';
import EventContentInfo from './EventContentInfo';
// import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'EventContent',
  data() {
    return {
      showTime: true,
    };
  },
  computed: {
    /* ...mapState({
      searchParams: (state) => state.aiCenter.searchParams,
    }), */
  },
  watch: {},
  components: {
    CameralRouteEvent,
    EventContentInfo,
  },
  created() {
    this.showTime = this.$route.query.time ? false : true;

    /* if (!this.showTime) {
      this._getDateList();
    } */
  },
  methods: {
    /*  _getDateList() {
      let searchParams = {
        ...this.searchParams,
        ...{ sj: `${this.$route.query.time} 00:00:00` },
      };
      this.searchTodayList(this.$route.query.time);
      this.setSearchParams(searchParams);
      this.getTotayList(searchParams);
    },
    searchTodayList(item) {
      let data = {
        endTime: `${item} 23:59:59`,
        eventAlarmDays: null,
        eventAlarmType: '',
        startTime: `${item} 00:00:00`,
      };
     // let newData = { ...this.searchParams, ...data }; 
      this.$api.aiCenterY.getEventHistogramStatistics(data).then((res) => {
        if (res.code === 200) {
          this.setBarData(res.data);
        }
      });
    }, */
    /*  ...mapActions(['getTotayList']),
    ...mapMutations(['setSearchParams', 'setBarData']), */
  },
};
</script>

<style scoped>
.f-l {
  width: 320px;
}

</style>
