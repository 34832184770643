<template>
  <div class="full-width full-height  bg-blue  text-lightblue p-md">
    <back url="/ai-center/index" />
    <!-- <tab :tabs="tabs" :initTab="0" /> -->
    <div style="height:calc(100% - 60px)" class="m-t-sm">
      <event-content />
    </div>
  </div>
</template>

<script>
import Back from '@/components/Back';
// import SearchBar from './components/SearchBar';
import EventContent from './components/EventContent';
// import Tab from '@/components/Tab';
// import { mapState, mapActions } from 'vuex';
export default {
  name: 'EventAll',
  data() {
    return {
      tabs: [{ name: '今日事件', index: 0 }],
    };
  },
  computed: {
    /* ...mapState({
      todayList: (state) => state.aiCenter.todayList,
      searchParams: (state) => state.aiCenter.searchParams,
    }), */
  },
  components: {
    Back,
    // SearchBar,
    // Tab,
    EventContent,
  },
  watch: {},
  created() {
    // this.getTotayList(this.searchParams);
  },
  methods: {
    // ...mapActions(['getTotayList']),
  },
};
</script>

<style scoped></style>
