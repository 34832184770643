<template>
  <div class="full-width full-height" id="chartBar"></div>
</template>

<script>
import echarts from '@/tools/echarts';
export default {
  name: 'EventChartBar',
  props: {
    type: {
      type: String,
      default: 'bar',
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      /* data: [
        {
          name: '5月20日',
          value: 20,
        },
        {
          name: '5月21日',
          value: 30,
        },
        {
          name: '5月22日',
          value: 20,
        },
        {
          name: '5月23日',
          value: 28,
        },
        {
          name: '5月24日',
          value: 22,
        },
      ], */
    };
  },
  computed: {},
  watch: {
    data() {
      this.$nextTick(() => {
        this.renderChartLine();
      });
    },
  },
  mounted() {
    /* this.$nextTick(() => {
      this.renderChartLine();
    }); */
  },
  methods: {
    renderChartLine() {
      let obj = document.getElementById('chartBar'); //"abnormal-chart"
      if (!obj) {
        return false;
      }
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      let myChart = echarts.init(obj);
      let legendArr = this.data.map((item) => item.name);
      let dataArr = this.data.map((item) => item.value);
      //var that = this;
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'item',
          formatter: '{b}<br />事件数量: {c}',
        },
        legend: {
          show: false,
          // orient: 'vertical',
          left: 'center',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        grid: {
          top: '10%',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },

          data: legendArr,
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(134, 179, 233, 0.1)'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisLabel: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },

        series: [
          {
            type: this.type,

            itemStyle: {
              color: '#3A689F',
            },
            barWidth: '15px',
            data: dataArr,
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped></style>
