<template>
  <div class="full-width full-height">
    <search-bar class="m-t-sm" @search="searchHandle" />
    <div class="hd flex items-center flex-end m-b-sm">
      <div>
        <a-button class="btn-export m-r-sm" @click="eventCombineHandle">
          报警合并
        </a-button>
        <a-button class="btn-export" @click="exportHandle">
          数据导出
        </a-button>
      </div>
    </div>

    <div class="table">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        bordered
        :columns="columns"
        :components="resibleTableHeader"
        :data-source="todayList"
        :scroll="scroll"
        :loading="loading"
        :rowKey="(record, index) => index"
        :pagination="paginationOpt"
      >
        <span
          slot="operation"
          slot-scope="text, record"
          class="table-operation"
        >
          <a-icon type="file-done" class="m-r-sm" @click="viewDetail(record)" />
          <a-icon type="rest" class="m-r-sm" @click="deleteHandle(record)" />
        </span>
      </a-table>
    </div>
    <div class="hd flex items-center justify-between" v-if="false">
      <title-com text="事件统计" />
    </div>
    <div style="height:200px" v-if="false">
      <chart-bar :data="chartBarData" />
    </div>

    <event-alarm-detail
      ref="eventAlarm"
      :visible="eventVisible"
      :cameraDetailId="cameraDetailId"
      :cameraDetail="cameraDetail"
      @close="closeDetail"
      @misReportHandle="misReportParentHandle"
    />
  </div>
</template>

<script>
import TitleCom from '@/components/TitleCom';
// import ChartBar from '@/components/ChartBar';
import ChartBar from '@/components/EventChartBar';
import EventAlarmDetail from '@/components/EventAlarmDetail';
import SearchBar from './SearchBar';
import { CODE_OK } from '@/request/config_code';
import { handleExportFile } from '@/tools';
import moment from 'moment';
import ResizableTable from '@/components/mixins/resizeableTable';
// import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'EventContentInfo',
  mixins:[ResizableTable],
  data() {
    return {
      selectedRowKeys: [],
      todayList: [],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.selectedRowKeys = [];
          this.selectedRowAll = [];
          this._queryEventByCondition();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.selectedRowKeys = [];
          this.selectedRowAll = [];
          this._queryEventByCondition();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex:'enventIndex',
          customRender: (text, record, index) => index + 1,
          width: 50,
        },
        {
          title: '事件类型',
          dataIndex: 'parentEventName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '路线',
          dataIndex: 'roadName',
          width: 120,
          ellipsis: true,
          /* customRender: (text, record) => {
            return `${record.roadCode} ${record.roadName}`;
          }, */
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          width:180,
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'pileNum',
          ellipsis: true,
          width: 100,
        },
        {
          title: '事件详情',
          dataIndex: 'eventName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '上报时间',
          dataIndex: 'startTime',
          width: 180,
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 80,
        },
      ],
      data: [
        /*  */
      ],
      eventVisible: false,
      cameraDetailId: '',
      cameraDetail: {},
      selectedRowAll: [],
      scroll: { y: window.innerHeight - 400 ,x:'100%'},
      searchParams: {
        currPage: 1,
        endTime: '',
        eventKey: '',
        pageSize: 10,
        parentEventKey: '',
        roadCode: '',
        startTime: '',
      },
      loading: false,
      parentEventId: '',
    };
  },
  components: {
    TitleCom,
    ChartBar,
    EventAlarmDetail,
    SearchBar,
  },
  computed: {
    /*  ...mapState({
      todayList: (state) => state.aiCenter.todayList,
      total: (state) => state.aiCenter.total,
      searchParams: (state) => state.aiCenter.searchParams,
      barData: (state) => state.aiCenter.barData,
    }), */
    /* chartBarData() {
      // console.error('haha', this.barData);
      return this.barData.map((item) => ({
        name: item.typeName,
        value: Number(item.eventAlarmNumber),
      }));
    }, */
  },
  watch: {
    /*  total(newTotal) {
      this.paginationOpt.total = newTotal;
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.selectedRowKeys = [];
      this.selectedRowAll = [];
    }, */
  },
  created() {
    // this.paginationOpt.total = this.total;
    //获取字典
    this.parentEventId = this.$route.query.parentEventId;
    this._queryEventByCondition();
  },
  methods: {
    //查询
    // eslint-disable-next-line no-unused-vars
    searchHandle(params) {
      this.searchParams = params;
      this._queryEventByCondition();
    },
    _queryEventByCondition() {
      let searchParams = {
        currPage: this.paginationOpt.current,
        endTime:
          this.searchParams.endTime ||
          `${moment().format('YYYY-MM-DD')} 23:59:59`,
        eventKey: this.searchParams.eventKey,
        pageSize: this.paginationOpt.defaultPageSize,
        parentEventId: this.searchParams.parentEventKey || this.parentEventId,
        roadCode: this.searchParams.roadCode,
        startTime:
          this.searchParams.startTime ||
          `${moment().format('YYYY-MM-DD')} 00:00:00`,
      };
      this.loading = true;
      this.$api.aiCenterY.queryEventByCondition(searchParams).then((res) => {
        if (res.code === CODE_OK) {
          this.todayList = res.data;
          this.paginationOpt.total = res.total;
          this.loading = false;
        }
      });
    },
    eventCombineHandle() {
      if(!this.hasPermission(109212121010)) {
        this.permissionWarning();
        return;
      }
      if (!this.selectedRowKeys.length) {
        this.$message.error('请选择同一台摄像机事件');
        return;
      }
      let obj = {
        hisIds: this.selectedRowAll.map((item) => item.hisId).join(','),
      };
      let objParams = {
        data: obj,
        instructionsDel: {
          module: '今日事件',
          page: '查看事件',
          feature: '合并事件',
          description: '合并事件',
        },
      };
      this.$api.aiCenterY.updateEvent(objParams).then((res) => {
        if (res.code === 200) {
          this.$message.success('事件合并成功');
          this.selectedRowKeys = [];
          this.selectedRowAll = [];
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = 10;
          this._queryEventByCondition();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    exportHandle() {
      if(!this.hasPermission(109212121012)) {
        this.permissionWarning();
        return;
      }
      if(this.todayList.length < 0) {
        this.$message.error('没有可导出的数据！');
      }
      let data = {
        endTime: this.searchParams.endTime,
        eventKey: this.searchParams.eventKey,
        parentEventId: this.searchParams.parentEventKey || this.parentEventId,
        roadCode: this.searchParams.roadCode,
        startTime: this.searchParams.startTime,
      };
      let objParams = {
        data,
        instructionsDel: {
          module: '今日事件',
          page: '查看事件',
          feature: '数据导出',
          description: '今日事件列表',
        },
      };
      this.$api.aiCenterY.exportEventInfo(objParams).then((res) => {
        this.selectedRowKeys = [];
        this.selectedRowAll = [];
        handleExportFile(res, 'AI事件列表.xls');
      });
    },
    misReportParentHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._queryEventByCondition();
    },
    onSelectChange(selectKeys, selectRowAll) {
      this.selectedRowKeys = selectKeys;
      this.selectedRowAll = selectRowAll;
    },
    closeDetail() {
      this.eventVisible = false;
      this.cameraDetailId = '';
      this.cameraDetail = {};
    },
    viewDetail(record) {
      this.eventVisible = true;
      this.cameraDetailId = record.hisId;
    },
    deleteHandle(record) {
      if(!this.hasPermission(109212121011)) {
        this.permissionWarning();
        return;
      }
      this.$confirm({
        title: '提示',
        content: '你确定删除该记录吗?',
        onOk: () => {
          let data = {
            id: record.hisId,
          };
          let objParams = {
            data,
            instructionsDel: {
              module: '今日事件',
              page: '查看事件',
              feature: '删除事件',
              description: record.eventName,
            },
          };
          this.$api.aiCenterY.misinformation(objParams).then((res) => {
            if (res.code === 200) {
              this.paginationOpt.current = 1;
              this.paginationOpt.defaultPageSize = 10;
              this._queryEventByCondition();
            }
          });
        },
        onCancel: () => {},
      });
    },

    /* changePage() {
      let params = {
        ...this.searchParams,
        ...{
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      this.selectedRowKeys = [];
      this.selectedRowAll = [];
      this.setSearchParams(params);
      this.getTotayList(params);
    },
    
    deleteCameraGroupCameraHandle(record) {
      this.$confirm({
        title: '提示',
        content: '你确定删除该记录吗?',
        onOk: () => {
          let data = {
            oid: record.oid,
          };
          this.$api.aiCenterY.deleteEventList(data).then((res) => {
            if (res.code === 200) {
              this.$message.success('删除记录成功');
              this.getTotayList(this.searchParams);
            }
          });
        },
        onCancel: () => {},
      });
    },
    
   
    eventCombineHandle() {
      let data = this.selectedRowAll.map((item) => item.oid);

      this.$api.aiCenterY.updateEvent(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('事件合并成功');
          this.getTotayList(this.searchParams);
        }
      });
    },
   
    ...mapActions(['getTotayList']),
    ...mapMutations(['setSearchParams']), */
  },
};
</script>

<style scoped></style>
